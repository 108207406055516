<template>
  <teleport to="#modals">
    <div
      class="fixed inset-0 overflow-y-auto cursor-default"
      data-cy="modal"
      :class="modalClass"
    >
      <div
        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-black opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          class="modal-base inline-block align-middle bg-white rounded-md text-left transform transition-all sm:my-8"
          :class="{
            'w-11/12': sizeVal === 'xl',
            'w-7/10': sizeVal === 'large',
            'w-5/10': sizeVal === 'regular',
            'w-125': sizeVal === 'small'
          }"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="absolute z-50 right-0 -mr-10 sm:-mr-15"
            data-cy="modal-close-btn"
          >
            <icon-base
              class="cursor-pointer"
              icon="x-large"
              width="44"
              height="44"
              @click="close"
            />
          </div>
          <div class="bg-white rounded-md sm:w-full">
            <div
              class="flex-column items-start border-gray-200 p-2 sm:p-6"
              :class="{ 'border-b': titleBorder }"
            >
              <div class="flex items-baseline space-x-5 pb-5">
                <lf-h1
                  v-if="title"
                  id="modal-headline"
                  class="whitespace-pre-wrap"
                >
                  {{ title }}
                </lf-h1>
                <slot name="type" />
              </div>
              <div class="-mb-6 flex">
                <slot name="header"></slot>
              </div>
            </div>
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { onBeforeUnmount, onMounted, computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    default: ""
  },
  close: {
    type: Function as PropType<() => void>,
    required: true
  },
  small: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  },
  xl: {
    type: Boolean,
    default: false
  },
  titleBorder: {
    type: Boolean,
    default: false
  },
  modalClass: {
    type: String,
    default: "z-100"
  }
});

const sizeVal = computed(() => {
  if (props.xl) {
    return "xl";
  }
  if (props.large) {
    return "large";
  }
  if (props.small) {
    return "small";
  }
  return "regular";
});

const escapeCallback = (keyboardEvent: KeyboardEvent) => {
  if (keyboardEvent.code === "Escape" && props.close) {
    props.close();
  }
};

onMounted(() => {
  document.addEventListener("keydown", escapeCallback);
});

onBeforeUnmount(() => {
  document.removeEventListener("keydown", escapeCallback);
});
</script>
